.custom-switch {
	display: block !important;
	background: $separator-color !important;
	width: 58px !important;
	height: 27px !important;
	border: 1px solid $separator-color !important;

	&.rc-switch-disabled {
		background: lighten($separator-color, 10%) !important;
		border: 1px solid lighten($separator-color, 10%) !important;
	}

	&:after {
		width: 18px !important;
		height: 18px !important;
		top: 3px !important;
		margin-left: 3px !important;
		box-shadow: initial;
		transform: initial !important;
		animation-name: unset !important;
		background: $foreground-color !important;
	}
	&.rc-switch:after {
		box-shadow: none !important;
	}

	&.rc-switch-checked:after {
		left: 30px !important;
		box-shadow: none !important;
	}

	&.rc-switch-checked.custom-switch-primary {
		background: $theme-color-1 !important;
		border: 1px solid $theme-color-1 !important;
		&.rc-switch-disabled {
			background: lighten($theme-color-1, 20%) !important;
			border: 1px solid lighten($theme-color-1, 20%) !important;
		}
	}

	&.rc-switch-checked.custom-switch-secondary {
		background: $theme-color-2 !important;
		border: 1px solid $theme-color-2 !important;
		&.rc-switch-disabled {
			background: lighten($theme-color-2, 20%) !important;
			border: 1px solid lighten($theme-color-2, 20%) !important;
		}
	}

	&.custom-switch-primary-inverse {
		border: 1px solid $separator-color !important;
	}

	&.rc-switch-checked.custom-switch-primary-inverse {
		background: $input-background !important;
		border: 1px solid $theme-color-1 !important;

		&:after {
			background: $theme-color-1 !important;
		}
	}

	&.custom-switch-secondary-inverse {
		border: 1px solid $separator-color !important;
	}

	&.rc-switch-checked.custom-switch-secondary-inverse {
		background: $input-background !important;
		border: 1px solid $theme-color-2 !important;

		&:after {
			background: $theme-color-2 !important;
		}
	}
}

.rc-switch:focus {
	box-shadow: initial !important;
}

.custom-switch .custom-switch-input + .custom-switch-btn {
	background: $separator-color !important;
}

.custom-switch.custom-switch-primary
	.custom-switch-input:checked
	+ .custom-switch-btn {
	background: $theme-color-1 !important;
	border: 1px solid $theme-color-1 !important;
}

.custom-switch.custom-switch-secondary
	.custom-switch-input:checked
	+ .custom-switch-btn {
	background: $theme-color-2 !important;
	border: 1px solid $theme-color-2 !important;
}

.custom-switch.custom-switch-primary-inverse
	.custom-switch-input
	+ .custom-switch-btn {
	border: 1px solid $separator-color !important;
}

.custom-switch.custom-switch-primary-inverse
	.custom-switch-input:checked
	+ .custom-switch-btn {
	background: $input-background !important;
	border: 1px solid $theme-color-1 !important;
}

.custom-switch.custom-switch-primary-inverse
	.custom-switch-input:checked
	+ .custom-switch-btn:after {
	background: $theme-color-1 !important;
}

.custom-switch.custom-switch-secondary-inverse
	.custom-switch-input
	+ .custom-switch-btn {
	border: 1px solid $separator-color !important;
}

.custom-switch.custom-switch-secondary-inverse
	.custom-switch-input:checked
	+ .custom-switch-btn {
	background: $input-background !important;
	border: 1px solid $theme-color-2 !important;
}

.custom-switch.custom-switch-secondary-inverse
	.custom-switch-input:checked
	+ .custom-switch-btn:after {
	background: $theme-color-2 !important;
}

.custom-switch .custom-switch-input + .custom-switch-btn:after {
	background: $input-background !important;
}

.custom-switch .custom-switch-input + .custom-switch-btn {
	border-color: $separator-color !important;
}

.rc-switch.custom-switch.custom-switch-small {
	width: 34px !important;
	height: 19px !important;
}

.rc-switch.custom-switch.custom-switch-small:after {
	width: 12px !important;
	height: 12px !important;
	left: 0px !important;
	top: 2px !important;
}

.rc-switch-checked.custom-switch.custom-switch-small:after {
	left: 14px !important;
}

.rc-switch-inner {
	.rc-switch-inner-checked,
	.rc-switch-inner-unchecked {
		font-size: 1rem;
		line-height: 24px;
	}
	.rc-switch-inner-checked {
		left: -24px;
	}
	.rc-switch-inner-unchecked {
		left: 26px;
	}
}

.rc-switch-checked {
	.rc-switch-inner {
		.rc-switch-inner-checked {
			left: 7px;
		}
		.rc-switch-inner-unchecked {
			left: 56px;
		}
	}
}
