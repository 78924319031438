header {
	position: fixed;
	width: 100%;
	z-index: 1049;
	background-color: rgba(255, 255, 255, 0.75);
	transition: background-color 0.25s linear;
	&.light {
		background-color: rgba(255, 255, 255, 0.75);
		.navbar-brand {
			background-image: url('/assets/logo/en.svg');

			&.zh-CN {
				background-image: url('/assets/logo/zh-CN.svg');
			}
		}
		.nav-link {
			color: black;
		}
	}
}

.navbar {
	// background: transparent;
	height: $navbar-height;
	padding: 0;
	margin-top: 0;
	// margin-left: -$navbar-height / 2;
	// margin-right: -$navbar-height / 2;

	.nav-link {
		font-size: 1.1rem;
		color: black;
		font-family: montserrat, 'PingFang SC', 'Microsoft YaHei', 'Hei SC', SimHei,
			'黑体', sans-serif;
		&:hover {
			color: $theme-color-1;
		}
	}
	.active {
		.nav-link {
			color: $theme-color-1;
		}
	}

	// .navbar-left {
	// 	padding-left: $navbar-height / 2;
	// }

	// .navbar-right {
	// 	padding-right: $navbar-height / 2 - 10;
	// }

	.navbar-brand {
		padding-top: 0;
		padding-bottom: 0;
		height: 80px;
		width: 130px;
		background: url('/assets/logo/en.svg') left center no-repeat;
		background-size: 130px;

		&.zh-CN {
			background-image: url('/assets/logo/zh-CN.svg');
		}
		@include respond-below(md) {
			height: 70px;
			width: 90px;
			background-size: 90px;
		}
		@include respond-below(sm) {
			height: 60px;
			width: 80px;
			background-size: 80px;
		}
	}

	.nav-right {
		width: 70px;
	}

	.navbar-nav {
		flex-direction: inherit;
	}

	@include respond-above(lg) {
		height: $navbar-height-lg;
	}

	@include respond-below(md) {
		height: $navbar-height-md;
		// margin-left: -$navbar-height-md / 2;
		// margin-right: -$navbar-height-md / 2;
		// .navbar-left {
		// 	padding-left: $navbar-height-md / 2;
		// }

		// .navbar-right {
		// 	padding-right: $navbar-height-md / 2;
		// }
	}

	@include respond-below(sm) {
		height: $navbar-height-sm;
		// margin-left: -$navbar-height-sm / 2;
		// margin-right: -$navbar-height-sm / 2;

		// .navbar-left {
		// 	padding-left: $navbar-height-sm / 2;
		// }

		// .navbar-right {
		// 	padding-right: $navbar-height-sm / 2;
		// }
	}
	@include respond-below(xs) {
		height: $navbar-height-sm;
		margin-top: 0;
		border-radius: 0;
		// margin-left: 0;
		// margin-right: 0;

		// .navbar-left {
		// 	padding-left: calc(var(--bs-gutter-x) * 0.5);
		// }

		// .navbar-right {
		// 	padding-right: calc(var(--bs-gutter-x) * 0.5);
		// }
	}
}

// .navbar-container {
// 	@include respond-below(xs) {
// 		padding-left: 0;
// 		padding-right: 0;
// 		max-width: 100%;
// 	}
// }

.language-switcher {
	color: white;
	&:hover {
		color: $theme-color-1;
	}
}
