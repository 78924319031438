.modal {
	.modal-header,
	.modal-body,
	.modal-footer {
		padding: 1rem 1.25rem;
	}

	.modal-header {
		border-bottom: 1px solid $separator-color;
	}

	.modal-footer {
		border-top: 1px solid $separator-color;
	}

	.close {
		color: $primary-color;
		text-shadow: initial;
	}
}

.modal-content {
	border: initial;
	border-radius: 0.1rem;
	background: $foreground-color;
}

.modal-dialog {
	@include depth(2);
}

.modal-right {
	padding-right: 0 !important;

	.modal-dialog {
		margin: 0 auto;
		margin-right: 0;
		margin-top: 0;
		margin-bottom: 0;
		height: 100%;
		max-width: 380px;
		&.modal-lg {
			max-width: 600px;
		}
	}

	.modal-content {
		height: 100%;
		position: relative;
		overflow-y: scroll;
		overflow-x: hidden;
	}

	.modal-header {
		padding: 0.8rem 1.25rem;
		width: 100%;
		position: fixed;
		background: $foreground-color;
		z-index: 3;
	}

	.modal-footer {
		position: fixed;
		bottom: 0;
		width: 100%;
		padding: 0.8rem 1.25rem;
		background: $foreground-color;
		z-index: 3;
	}

	.modal-body {
		padding: 80px 1.25rem;
	}

	.modal.fade .modal-dialog {
		transform: translate(25%, 0) !important;
	}

	.modal.show .modal-dialog {
		transform: translate(0, 0) !important;
	}
}

.modal-full {
	.modal-dialog {
		height: 100%;
		margin: 0 auto;
		margin-top: 0;
		margin-bottom: 0;
		box-shadow: none;
		min-width: 100%;
	}

	.modal-content {
		width: 100%;
		height: 100%;
		box-shadow: 0 3px 30px rgba(0, 0, 0, 0.1), 0 3px 20px rgba(0, 0, 0, 0.1);
	}

	.modal-header {
		padding: 3rem;
		width: 100%;
		position: absolute;
		top: 0;
		background: $foreground-color;
		z-index: 3;
		height: 80px;
		border-bottom: 0;
		.btn-close {
			background-size: 1.5em;
		}
	}

	.modal-footer {
		position: absolute;
		bottom: 0;
		width: 100%;
		padding: 0.8rem 1.25rem;
		background: $foreground-color;
		z-index: 3;
	}

	.modal-body {
		padding: 120px 1.25rem;
		max-height: 100vh;
		position: relative;
		overflow-y: scroll;
		overflow-x: hidden;
		-ms-overflow-style: none;
		&::-webkit-scrollbar {
			width: 0 !important;
		}
	}

	@include respond-below(xs) {
		.modal-dialog {
			margin: 0;
			height: 100%;
		}
		.modal-content {
			height: 100%;
		}
	}
}

.rtl {
	.modal {
		.modal-header,
		.modal-body,
		.modal-footer {
			padding: 1.75rem;
			@include respond-below(xs) {
				padding: 1.5rem;
			}
		}
	}
}
