button {
	color: $primary-color;
	outline: initial !important;
}

.btn-arrow {
	display: inline-block;
	text-align: center;
	border-radius: 30px !important;
	width: 42px;
	height: 42px;
	line-height: 24px;
}

.btn-arrow i {
	font-size: 15px;
	display: inline-block;
	text-align: center;
}

.btn-sm.btn-arrow {
	width: 34px;
	height: 34px;
	line-height: 17px;
}

.btn-sm.btn-arrow i {
	font-size: 13px;
	line-height: 10px;
}

.btn {
	border-radius: 0px;
	outline: initial !important;
	box-shadow: none !important;
	box-shadow: initial !important;
	font-size: 0.8rem;
	padding: 0.5rem 1.25rem 0.5rem 1.25rem;
	transition: background-color box-shadow 0.1s linear;
}

.btn-shadow {
	box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15), 0 1px 3px 1px rgba(0, 0, 0, 0.15) !important;
	transition: background-color box-shadow 0.1s linear;

	&:hover,
	&:focus {
		box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15),
			0 4px 6px 2px rgba(0, 0, 0, 0.15) !important;
	}
}

.btn-empty {
	background: transparent !important;
}

// .btn-lg,
// .btn-group-lg > .btn,
// .btn-group-sm > .btn {
// 	border-radius: 50px;
// }

.btn.default {
	border-radius: $border-radius;
}

.btn-primary {
	background-color: $theme-color-1;
	border-color: $theme-color-1;
	color: $button-text-color;

	&:hover,
	&:active,
	&:focus {
		color: darken($theme-color-1, 8%) !important;
		background-color: white !important;
		border-color: darken($theme-color-1, 8%) !important;
	}
}

.check-button {
	cursor: default !important;
}

.check-button.btn-primary {
	background-color: $theme-color-1 !important;
	border-color: $theme-color-1 !important;
	opacity: 1;
}

.check-button .custom-control {
	min-height: 1.1rem;
	margin-top: -7px;
}

.dropdown-menu {
	font-size: 0.8rem;
	border-radius: $border-radius;
	background: $input-background;
	border-color: rgba($primary-color, 0.15);
}

.dropdown-header {
	font-size: 0.75rem;
	padding: 0.5rem 1.25rem;
}

.dropdown-item {
	font-size: 1rem;
	padding: 0.5rem 1.25rem;
	color: $primary-color;
}

.dropdown-item:hover,
.dropdown-item:focus {
	background-color: $background-color;
	color: $primary-color;
}

.dropdown-item.active,
.dropdown-item:active {
	color: $button-text-color;
	text-decoration: none;
	background-color: $theme-color-1;
}

.dropdown-divider {
	border-color: $separator-color;
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
	background-color: darken($theme-color-1, 12%);
	border-color: darken($theme-color-1, 12%);
	color: $button-text-color;
}

.btn-secondary {
	background-color: $theme-color-2;
	border-color: $theme-color-2;
	color: $button-text-color;

	&:hover,
	&:active,
	&:focus {
		color: $button-text-color;
		background-color: darken($theme-color-2, 8%);
		border-color: darken($theme-color-2, 8%);
	}
}

.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
	background-color: darken($theme-color-2, 12%);
	border-color: darken($theme-color-2, 12%);
	color: $button-text-color;
}

.btn-primary-gradient {
	background: linear-gradient(105deg, $theme-color-2, $theme-color-1);
	color: white;
	border: 0;

	&:hover,
	&:active,
	&:focus {
		background: linear-gradient(
			105deg,
			darken($theme-color-2, 8%),
			darken($theme-color-1, 8%)
		);
		color: white !important;
	}
}

.btn-primary-gradient:not(:disabled):not(.disabled):active,
.btn-primary-gradient:not(:disabled):not(.disabled).active,
.show > .btn-primary-gradient.dropdown-toggle {
	background: linear-gradient(
		105deg,
		darken($theme-color-2, 8%),
		darken($theme-color-1, 8%)
	);
	color: white;
}

.btn-secondary-gradient {
	background: linear-gradient(105deg, $theme-color-3, $theme-color-1);

	&:hover,
	&:active,
	&:focus {
		background: linear-gradient(
			to right,
			$theme-color-2,
			darken($theme-color-2, 8%)
		);
	}
}

.btn-secondary-gradient:not(:disabled):not(.disabled):active,
.btn-secondary-gradient:not(:disabled):not(.disabled).active,
.show > .btn-secondary-gradient.dropdown-toggle {
	background: linear-gradient(105deg, $theme-color-3, $theme-color-1);
}

.btn-warning {
	background-color: $warning-color;
	border-color: $warning-color;
}

.btn-success,
.btn-info,
.btn-danger,
.btn-warning {
	color: $button-text-color;

	&:hover,
	&:active,
	&:focus {
		color: $button-text-color;
	}
}

.btn-outline-success,
.btn-outline-info,
.btn-outline-danger,
.btn-outline-warning {
	&:hover,
	&:active,
	&:focus {
		color: $foreground-color;
	}
}

.btn-light {
	color: $dark-btn-background;
	background-color: $light-btn-background;
	border-color: $light-btn-background;

	&:hover,
	&:active,
	&:focus {
		color: $dark-btn-background;
		background-color: darken($light-btn-background, 8%);
		border-color: darken($light-btn-background, 8%);
	}
}

.btn-dark {
	color: $light-btn-background;
	background-color: $dark-btn-background;
	border-color: $dark-btn-background;

	&:hover,
	&:active,
	&:focus {
		color: $light-btn-background;
		background-color: darken($dark-btn-background, 5%);
		border-color: darken($dark-btn-background, 5%);
	}
}

.btn-outline-dark {
	color: $dark-btn-background;
	border-color: $dark-btn-background;

	&:hover,
	&:active,
	&:focus {
		color: $foreground-color;
		background-color: $dark-btn-background;
		border-color: $dark-btn-background;
	}
}

.btn-outline-white {
	color: $button-text-color;
	border-color: $button-text-color;
	background-color: initial;

	&:hover,
	&:active,
	&:focus {
		color: $theme-color-1;
		background-color: $button-text-color;
	}
}

.btn-outline-light {
	color: $light-btn-background;
	border-color: $light-btn-background;

	&:hover,
	&:active,
	&:focus {
		color: $foreground-color;
		background-color: $light-btn-background;
		border-color: $light-btn-background;
	}
}

.btn-outline-primary {
	color: $theme-color-1;
	border-color: $theme-color-1;

	&:hover,
	&:active,
	&:focus {
		color: $foreground-color;
		background-color: $theme-color-1;
		border-color: $theme-color-1;
	}
}

.btn-outline-theme-3 {
	background: unset;
	color: $theme-color-3;
	border-color: $theme-color-3;

	&:hover,
	&:active,
	&:focus {
		background-color: $theme-color-3;
		border-color: $theme-color-3;
		color: $foreground-color;
	}
}

.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
	background-color: $theme-color-1;
	border-color: $theme-color-1;
	color: $foreground-color;
}

.btn-outline-secondary {
	color: $theme-color-2;
	border-color: $theme-color-2;

	&:hover,
	&:active,
	&:focus {
		background-color: $theme-color-2;
		border-color: $theme-color-2;
		color: $foreground-color;
	}
}

.btn-outline-secondary:not(:disabled):not(.disabled):active,
.btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .btn-outline-secondary.dropdown-toggle {
	background-color: $theme-color-2;
	border-color: $theme-color-2;
	color: $foreground-color;
}

.btn-header-light {
	color: $separator-color;
	border-color: transparent;
	background: transparent;

	&:hover,
	&:active,
	&:focus {
		background-color: transparent;
		border-color: $separator-color;
	}
}

.btn-header-primary {
	color: $theme-color-1;
	border-color: transparent;
	background: transparent;

	&:hover,
	&:active,
	&:focus {
		background-color: transparent;
		border-color: $theme-color-1;
	}
}

.btn-header-secondary {
	color: $theme-color-2;
	border-color: transparent;
	background: transparent;

	&:hover,
	&:active,
	&:focus {
		background-color: transparent;
		border-color: $theme-color-2;
	}
}

.btn-header-primary-light {
	color: $theme-color-3;
	border-color: transparent;
	background: transparent;

	&:hover,
	&:active,
	&:focus {
		background-color: transparent;
		border-color: $theme-color-3;
	}
}

.btn-xl,
.btn-group-xl > .btn {
	line-height: 1.5;
	font-weight: 700;
	letter-spacing: 0.05rem;
	padding: 1rem 3.5rem 0.9rem;
}

.btn-lg,
.btn-group-lg > .btn {
	line-height: 1.5;
	font-weight: 700;
	letter-spacing: 0.05rem;
	padding: 0.6rem 2.5rem 0.6rem 2.5rem;
}

.btn-sm,
.btn-group-sm > .btn {
	padding: 0.45rem 1rem 0.45rem 1rem;
	font-size: 0.8rem;
	line-height: 1.5;
}

.btn-xs,
.btn-group-xs > .btn {
	padding: 0.25rem 0.75rem 0.25rem 0.75rem;
	font-size: 0.76rem;
	line-height: 1.3;
}

.btn-primary.disabled,
.btn-primary:disabled {
	background-color: $theme-color-1;
	border-color: $theme-color-1;
	color: $foreground-color;
}

.btn-secondary.disabled,
.btn-secondary:disabled {
	background-color: $theme-color-2;
	border-color: $theme-color-2;
	color: $foreground-color;
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
	border-top-right-radius: 0 !important;
	border-bottom-right-radius: 0 !important;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
	border-top-left-radius: 0 !important;
	border-bottom-left-radius: 0 !important;
}

.btn-link {
	color: $theme-color-1;
}

.btn-link:hover {
	color: rgba($theme-color-1, 0.8);
	text-decoration: underline;
}

.white-underline-link {
	color: $button-text-color;
	text-decoration: underline;

	&:hover,
	&:active,
	&:focus {
		color: $button-text-color;
		text-decoration: initial;
	}
}

.btn-multiple-state {
	position: relative;
	transition: opacity 500ms;

	.spinner,
	.icon {
		position: absolute;
		left: 0;
		right: 0;
		margin: 0 auto;
		opacity: 0;
		visibility: hidden;
		top: 50%;
		transform: translateY(-50%);
		transition: opacity 500ms;
		color: $button-text-color;
	}

	.icon i {
		vertical-align: text-bottom;
		font-size: 18px;
	}

	.label {
		transition: opacity 500ms;
	}

	&.show-spinner {
		.label {
			opacity: 0;
		}

		.spinner {
			opacity: 1;
			visibility: visible;
		}
	}

	&.show-success {
		.label {
			opacity: 0;
		}

		.icon.success {
			opacity: 1;
			visibility: visible;
		}
	}

	&.show-fail {
		.label {
			opacity: 0;
		}

		.icon.fail {
			opacity: 1;
			visibility: visible;
		}
	}

	&.btn-primary:disabled {
		opacity: 1;
		background: darken($theme-color-1, 10%);
		border-color: darken($theme-color-1, 10%);
	}

	&.btn-secondary:disabled {
		opacity: 1;
		border-color: darken($theme-color-2, 10%);
	}
}

.icon-button {
	padding: 0;
	font-size: 14px;
	width: 34px;
	height: 34px;
	line-height: 34px;

	&.large {
		width: 44px;
		height: 44px;
		font-size: 18px;
	}

	&.small-icon {
		font-size: 12px;
		line-height: 32px;
	}
}

.top-right-button-single {
	width: unset;

	@include respond-below(md) {
		width: calc(100%);
	}
}

.top-right-button-container {
	float: right;
	position: relative;

	.btn-group .btn {
		line-height: 1;
	}

	@include respond-below(xs) {
		float: initial;
		display: flex;
		justify-content: space-between;
	}

	.top-right-button {
		@include respond-below(xs) {
			display: flex;
			flex-grow: 1;
			justify-content: center;
			align-items: center;
			margin-right: 5px;
		}
	}

	.dropdown {
		@include respond-below(xs) {
			display: flex;
			flex-grow: 1;
			margin-right: 5px;
		}
	}
}
