$navbar-height: 80px;
$navbar-height-sm: 60px;
$navbar-height-md: 80px;
$navbar-height-lg: 80px;

$main-margin: 60px;
$main-margin-lg: 50px;
$main-margin-md: 40px;
$main-margin-xs: 15px;

$animation-time-long: 1000ms;
$animation-time-short: 200ms;

$border-radius: 0.1rem;
$border-radius-rounded: 0.75rem;

$theme-color-default: #ff3d00;
$theme-color-purple-monster: #dfbedb;
$theme-color-blue-navy: #00365a;
$theme-color-blue-yale: #145388;
$theme-color-blue-olympic: #008ecc;
$theme-color-green-moss: #576a3d;
$theme-color-green-lime: #6fb327;
$theme-color-red-ruby: #900604;
$theme-color-yellow-granola: #c0a145;
$theme-color-grey-steel: #48494b;

$info-color: #3195a5;
$success-color: #3e884f;
$warning-color: #b69329;
$error-color: #c43d4b;

$separator-color-light: #f3f3f3;
$separator-color: #d7d7d7;
$background-color: #f7f7f7;
$foreground-color: white;
$input-background: white;

$dark-btn-background: #131113;
$light-btn-background: #ececec;

$button-text-color: #fff;

$theme-color-1: #972b43;
$theme-color-2: #679cce;
$theme-color-3: #aaba9f;
$theme-color-4: #849b65;
$theme-color-5: #3db264;
$theme-color-6: #9ecd7e;

$primary-color: #3a3a3a;
$secondary-color: #8f8f8f;
$muted-color: #909090;
$text-color: #1c1c1c;
$text-dark-gray-color: #333;

$gradient-color-1: #55c380;
$gradient-color-2: #6c9e37;
$gradient-color-3: #76a543;

$lp-bg-color-1: #426d14;
$lp-bg-color-2: #51831d;
$lp-bg-color-3: #70ad2e;
$lp-bg-color-4: #8de231;

$shadow-offsets-top: 1 3 10 14 19;
$shadow-blurs-top: 1.5 5 10 14 19;
$shadow-opacities-top: 0.04 0.1 0.19 0.25 0.3;

$shadow-offsets-bottom: 1 3 6 10 15;
$shadow-blurs-bottom: 3 6 6 5 6;
$shadow-opacities-bottom: 0.04 0.1 0.2 0.22 0.22;

$divider-bottom-image: "<svg id='comp-li9d56fk1-bottom' preserveAspectRatio='none' data-bbox='0 91 1920 209' viewBox='0 91 1920 209' height='100%' width='100%' xmlns='http://www.w3.org/2000/svg' data-type='shape'><defs><style>#comp-li9d56fk1-bottom > g { transform: scaleY(-1) translateY(-391px); } #comp-li9d56fk1-bottom { fill: #F7F7F7; }</style></defs><g><path fill-rule='evenodd' d='M1920 291C1656.8 169.2 1323.3 91 960 91S262.5 169.2 0 291v9h1920v-9z M0,90 h1920 v210 h-1920 Z'/></g></svg>";
