.about {
	width: 100%;
	overflow-x: hidden;
	.section-1 {
		margin-bottom: 3rem;
		padding-bottom: 3rem;
		text-align: center;
		.banner {
			width: 100%;
			height: 800px;
			position: relative;
			video {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
			.container {
				height: 800px;
			}
			.slogan {
				line-height: 1.25;
				color: white;
				position: relative;
			}
		}
		.desc {
			p {
				font-size: 1.5rem;
				line-height: 1.8;
				@include respond-below(sm) {
					font-size: 1.25rem;
					line-height: 1.5;
				}
			}
		}
	}
	.section-2 {
		width: 100%;
		background: url('/assets/img/about-bg-we.jpg') center center no-repeat;
		background-size: cover;
		color: white;
		padding: 10rem 0;
		@include respond-below(sm) {
			padding: 5rem 0;
		}
		.we {
			width: 200px;
			height: 200px;
			position: relative;
			@include respond-below(sm) {
				margin-bottom: 5rem;
			}
			&:hover {
				.shape {
					transform: rotate(45deg);
				}
			}
			.shape {
				position: absolute;
				top: 0;
				left: 0;
				transition: 1s all;
				svg {
					path {
						fill: rgb(199, 67, 0);
					}
				}
			}
			.text {
				position: relative;
				text-align: center;
				z-index: 1047;
				height: 200px;
				width: 200px;
				margin-bottom: 0;
				display: table-cell;
				vertical-align: middle;
				font-size: 4rem;
				padding: 5px;
			}
		}
		.desc {
			position: relative;
			width: 100%;
			p {
				font-size: 1.5rem;
				line-height: 1.5;
			}
			.divider-line {
				position: relative;
				width: 100%;
				border-bottom: white 1px solid;
			}
			.divider-line-layer {
				margin-left: 80%;
				border-bottom: white 5px solid;
				margin-bottom: -2px;
				transition: 1s linear;
				transition-delay: 200ms;
			}
			.divider-line-layer-0 {
				width: 60%;
			}
			.divider-line-layer-1 {
				width: 30%;
			}
			.divider-line-layer-2 {
				width: 80%;
			}
			&.animated {
				.divider-line-layer-0 {
					margin-left: 5%;
				}
				.divider-line-layer-1 {
					margin-left: 60%;
				}
				.divider-line-layer-2 {
					margin-left: 10%;
				}
			}
		}
	}
	.section-3 {
		position: relative;
		width: 100%;
		padding: 10rem 0;
		.title {
			position: relative;
			top: 80px;
			text-align: center;
			margin-bottom: 5rem;
			transition: 1s all;
			transition-delay: 200ms;
			&.animated {
				top: 0;
			}
		}
		.video-ai {
			text-align: center;
			video {
				height: 320px;
				@include respond-below(sm) {
					height: 240px;
				}
				object-fit: cover;
				-webkit-mask-image: url('/assets/img/about-mask-1.svg');
				-webkit-mask-position: center center;
				-webkit-mask-repeat: no-repeat;
				-webkit-mask-size: 410px 320px;

				mask-image: url('/assets/img/about-mask-1.svg');
				mask-position: center center;
				mask-repeat: no-repeat;
				mask-size: 410px 320px;
				@include respond-below(sm) {
					-webkit-mask-size: 360px 240px;
					mask-size: 360px 240px;
				}
			}
			p {
				margin-top: 2rem;
				font-size: 1.5rem;
			}
		}
		.video-bm {
			text-align: center;
			video {
				height: 320px;
				object-fit: cover;
				-webkit-mask-image: url('/assets/img/about-mask-2.svg');
				-webkit-mask-position: center center;
				-webkit-mask-repeat: no-repeat;
				-webkit-mask-size: 300px 320px;

				mask-image: url('/assets/img/about-mask-2.svg');
				mask-position: center center;
				mask-repeat: no-repeat;
				mask-size: 300px 320px;
			}
			p {
				margin-top: 2rem;
				font-size: 1.5rem;
			}
		}
		.video-butterfly {
			text-align: center;
			.video-container {
				width: 400px;
				height: 348px;
				margin: 0 auto;
			}
			p {
				margin-top: 2rem;
				font-size: 1.5rem;
			}
		}
		.icon {
			width: 180px;
			height: 180px;
			position: relative;
			margin-bottom: 2rem;
			&:hover {
				.shape {
					transform: rotate(30deg);
				}
			}
			.shape {
				position: absolute;
				top: 0;
				left: 0;
				transition: 1s all;
				transition-delay: 200ms;
				&.gray {
					svg {
						path {
							fill: gray;
						}
					}
				}
				&.blue {
					svg {
						path {
							fill: rgb(18, 93, 179);
						}
					}
				}
				&.orange {
					svg {
						path {
							fill: rgb(255, 85, 1);
						}
					}
				}
				svg {
					height: 180px;
					width: 180px;
					path {
						fill: gray;
					}
				}
			}
			.text {
				display: table-cell;
				vertical-align: middle;
				position: relative;
				text-align: center;
				z-index: 1047;
				color: white;
				width: 180px;
				padding: 0 20px;
				height: 180px;
				margin: 0 auto;
			}
		}
	}
	.section-4 {
		position: relative;
		padding: 5rem 0;
		&:after {
			content: ' ';
			display: block;
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			opacity: 0.6;
			background: url('/assets/img/about-bg-look-for.jpg') center center
				no-repeat;
			background-size: cover;
		}
		.title {
			position: relative;
			top: 80px;
			z-index: 1047;
			text-align: center;
			opacity: 0;
			transition: 1s all;
			transition-delay: 200ms;
			margin-bottom: 1rem;
			&.animated {
				top: 0;
				opacity: 1;
			}
		}
		.items {
			position: relative;
			z-index: 1047;
			.item {
				padding: 8rem 5rem;
				@include respond-below(sm) {
					padding: 1rem 5rem;
				}
				p {
					font-size: 1.5rem;
					line-height: 1.5;
					text-align: center;
					display: table-cell;
					vertical-align: middle;
					height: 100px;
					opacity: 0;
					transition: 1s all;
					transition-delay: 200ms;
				}

				.divider-line {
					margin-top: 2rem;
					width: 100%;
					border-bottom: black 1px solid;
				}
				.divider-line-layer {
					border-bottom: black 3px solid;
					margin-bottom: -2px;
					transition: 0.5s linear;
					transition-delay: 200ms;
					width: 70%;
					margin-left: 50%;
				}
				&.animated {
					p {
						opacity: 1;
					}
					.divider-line-layer {
						margin-left: 15%;
					}
				}
			}
		}
	}
}
