.portfolio {
	width: 100%;
	overflow-x: hidden;
	.section-1 {
		margin-bottom: 3rem;
		padding-bottom: 3rem;
		text-align: center;
		.banner {
			width: 100%;
			height: 650px;
			position: relative;

			@include respond-below(md) {
				height: 500px;
			}
			@include respond-below(sm) {
				height: 400px;
			}
			video {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
			.container {
				height: 650px;
				@include respond-below(md) {
					height: 500px;
				}
				@include respond-below(sm) {
					height: 400px;
				}
			}
			.slogan {
				line-height: 1.25;
				color: white;
				position: relative;
			}
		}
		.desc {
			p {
				font-size: 1.5rem;
				line-height: 1.8;
				@include respond-below(sm) {
					font-size: 1.25rem;
					line-height: 1.5;
				}
			}
		}
	}
	.companies {
		position: relative;
		z-index: 1031;
		.company {
			position: relative;
			margin-bottom: 2rem;
			display: inline-block;
			.bg {
				width: 100%;
				opacity: 1;
			}
			.content {
				position: absolute;
				top: 0;
				bottom: 0;
				width: 100%;
				padding: 0;

				.logo-container {
					position: absolute;
					left: 0;
					top: 0;
					bottom: 0;
					display: flex;
					align-items: center;
					.logo {
						width: 100%;
					}
					transition: 0.3s linear;
				}
				.brief {
					width: 100%;
					position: relative;
					top: 45%;
					opacity: 0;
					text-align: center;
					color: white;
					padding: 15px;
					white-space: normal;
					font-size: 1rem;
					line-height: 1.5;
					transition: 0.5s linear;
				}
				.learn-more {
					opacity: 0;
					position: absolute;
					width: 100%;
					bottom: 0;
					text-align: center;
					color: white;
					font-size: 0.75rem;
					padding-bottom: 1rem;
					transition: 0.5s linear;
				}
			}
			&:hover {
				display: block;
				align-items: unset;
				.bg {
					opacity: 0;
				}
				.content {
					.logo-container {
						bottom: 50%;
					}
					.brief {
						opacity: 1;
					}
					.learn-more {
						opacity: 1;
					}
				}
			}
		}
	}
}
