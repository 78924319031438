@font-face {
	font-family: 'Suez One';
	src: url('/assets/fonts/suezone/SuezOne-Regular.eot');
	src: url('/assets/fonts/suezone/SuezOne-Regular.eot?#iefix')
			format('embedded-opentype'),
		url('/assets/fonts/suezone/SuezOne-Regular.woff2') format('woff2'),
		url('/assets/fonts/suezone/SuezOne-Regular.woff') format('woff'),
		url('/assets/fonts/suezone/SuezOne-Regular.ttf') format('truetype'),
		url('/assets/fonts/suezone/SuezOne-Regular.svg#SuezOne-Regular')
			format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}
@font-face {
	font-family: 'Montserrat';
	src: url('/assets/fonts/montserrat/Montserrat-BoldItalic.eot');
	src: url('/assets/fonts/montserrat/Montserrat-BoldItalic.eot?#iefix')
			format('embedded-opentype'),
		url('/assets/fonts/montserrat/Montserrat-BoldItalic.woff2') format('woff2'),
		url('/assets/fonts/montserrat/Montserrat-BoldItalic.woff') format('woff'),
		url('/assets/fonts/montserrat/Montserrat-BoldItalic.ttf') format('truetype'),
		url('/assets/fonts/montserrat/Montserrat-BoldItalic.svg#Montserrat-BoldItalic')
			format('svg');
	font-weight: bold;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Montserrat';
	src: url('/assets/fonts/montserrat/Montserrat-Bold.eot');
	src: url('/assets/fonts/montserrat/Montserrat-Bold.eot?#iefix')
			format('embedded-opentype'),
		url('/assets/fonts/montserrat/Montserrat-Bold.woff2') format('woff2'),
		url('/assets/fonts/montserrat/Montserrat-Bold.woff') format('woff'),
		url('/assets/fonts/montserrat/Montserrat-Bold.ttf') format('truetype'),
		url('/assets/fonts/montserrat/Montserrat-Bold.svg#Montserrat-Bold')
			format('svg');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Montserrat';
	src: url('/assets/fonts/montserrat/Montserrat-ExtraBold.eot');
	src: url('/assets/fonts/montserrat/Montserrat-ExtraBold.eot?#iefix')
			format('embedded-opentype'),
		url('/assets/fonts/montserrat/Montserrat-ExtraBold.woff2') format('woff2'),
		url('/assets/fonts/montserrat/Montserrat-ExtraBold.woff') format('woff'),
		url('/assets/fonts/montserrat/Montserrat-ExtraBold.ttf') format('truetype'),
		url('/assets/fonts/montserrat/Montserrat-ExtraBold.svg#Montserrat-ExtraBold')
			format('svg');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Montserrat';
	src: url('/assets/fonts/montserrat/Montserrat-BlackItalic.eot');
	src: url('/assets/fonts/montserrat/Montserrat-BlackItalic.eot?#iefix')
			format('embedded-opentype'),
		url('/assets/fonts/montserrat/Montserrat-BlackItalic.woff2') format('woff2'),
		url('/assets/fonts/montserrat/Montserrat-BlackItalic.woff') format('woff'),
		url('/assets/fonts/montserrat/Montserrat-BlackItalic.ttf')
			format('truetype'),
		url('/assets/fonts/montserrat/Montserrat-BlackItalic.svg#Montserrat-BlackItalic')
			format('svg');
	font-weight: 900;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Montserrat';
	src: url('/assets/fonts/montserrat/Montserrat-Black.eot');
	src: url('/assets/fonts/montserrat/Montserrat-Black.eot?#iefix')
			format('embedded-opentype'),
		url('/assets/fonts/montserrat/Montserrat-Black.woff2') format('woff2'),
		url('/assets/fonts/montserrat/Montserrat-Black.woff') format('woff'),
		url('/assets/fonts/montserrat/Montserrat-Black.ttf') format('truetype'),
		url('/assets/fonts/montserrat/Montserrat-Black.svg#Montserrat-Black')
			format('svg');
	font-weight: 900;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Montserrat';
	src: url('/assets/fonts/montserrat/Montserrat-ExtraBoldItalic.eot');
	src: url('/assets/fonts/montserrat/Montserrat-ExtraBoldItalic.eot?#iefix')
			format('embedded-opentype'),
		url('/assets/fonts/montserrat/Montserrat-ExtraBoldItalic.woff2')
			format('woff2'),
		url('/assets/fonts/montserrat/Montserrat-ExtraBoldItalic.woff')
			format('woff'),
		url('/assets/fonts/montserrat/Montserrat-ExtraBoldItalic.ttf')
			format('truetype'),
		url('/assets/fonts/montserrat/Montserrat-ExtraBoldItalic.svg#Montserrat-ExtraBoldItalic')
			format('svg');
	font-weight: bold;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Montserrat';
	src: url('/assets/fonts/montserrat/Montserrat-ExtraLight.eot');
	src: url('/assets/fonts/montserrat/Montserrat-ExtraLight.eot?#iefix')
			format('embedded-opentype'),
		url('/assets/fonts/montserrat/Montserrat-ExtraLight.woff2') format('woff2'),
		url('/assets/fonts/montserrat/Montserrat-ExtraLight.woff') format('woff'),
		url('/assets/fonts/montserrat/Montserrat-ExtraLight.ttf') format('truetype'),
		url('/assets/fonts/montserrat/Montserrat-ExtraLight.svg#Montserrat-ExtraLight')
			format('svg');
	font-weight: 200;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Montserrat';
	src: url('/assets/fonts/montserrat/Montserrat-Italic.eot');
	src: url('/assets/fonts/montserrat/Montserrat-Italic.eot?#iefix')
			format('embedded-opentype'),
		url('/assets/fonts/montserrat/Montserrat-Italic.woff2') format('woff2'),
		url('/assets/fonts/montserrat/Montserrat-Italic.woff') format('woff'),
		url('/assets/fonts/montserrat/Montserrat-Italic.ttf') format('truetype'),
		url('/assets/fonts/montserrat/Montserrat-Italic.svg#Montserrat-Italic')
			format('svg');
	font-weight: normal;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Montserrat';
	src: url('/assets/fonts/montserrat/Montserrat-Medium.eot');
	src: url('/assets/fonts/montserrat/Montserrat-Medium.eot?#iefix')
			format('embedded-opentype'),
		url('/assets/fonts/montserrat/Montserrat-Medium.woff2') format('woff2'),
		url('/assets/fonts/montserrat/Montserrat-Medium.woff') format('woff'),
		url('/assets/fonts/montserrat/Montserrat-Medium.ttf') format('truetype'),
		url('/assets/fonts/montserrat/Montserrat-Medium.svg#Montserrat-Medium')
			format('svg');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Montserrat';
	src: url('/assets/fonts/montserrat/Montserrat-LightItalic.eot');
	src: url('/assets/fonts/montserrat/Montserrat-LightItalic.eot?#iefix')
			format('embedded-opentype'),
		url('/assets/fonts/montserrat/Montserrat-LightItalic.woff2') format('woff2'),
		url('/assets/fonts/montserrat/Montserrat-LightItalic.woff') format('woff'),
		url('/assets/fonts/montserrat/Montserrat-LightItalic.ttf')
			format('truetype'),
		url('/assets/fonts/montserrat/Montserrat-LightItalic.svg#Montserrat-LightItalic')
			format('svg');
	font-weight: 300;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Montserrat';
	src: url('/assets/fonts/montserrat/Montserrat-ExtraLightItalic.eot');
	src: url('/assets/fonts/montserrat/Montserrat-ExtraLightItalic.eot?#iefix')
			format('embedded-opentype'),
		url('/assets/fonts/montserrat/Montserrat-ExtraLightItalic.woff2')
			format('woff2'),
		url('/assets/fonts/montserrat/Montserrat-ExtraLightItalic.woff')
			format('woff'),
		url('/assets/fonts/montserrat/Montserrat-ExtraLightItalic.ttf')
			format('truetype'),
		url('/assets/fonts/montserrat/Montserrat-ExtraLightItalic.svg#Montserrat-ExtraLightItalic')
			format('svg');
	font-weight: 200;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Montserrat';
	src: url('/assets/fonts/montserrat/Montserrat-Light.eot');
	src: url('/assets/fonts/montserrat/Montserrat-Light.eot?#iefix')
			format('embedded-opentype'),
		url('/assets/fonts/montserrat/Montserrat-Light.woff2') format('woff2'),
		url('/assets/fonts/montserrat/Montserrat-Light.woff') format('woff'),
		url('/assets/fonts/montserrat/Montserrat-Light.ttf') format('truetype'),
		url('/assets/fonts/montserrat/Montserrat-Light.svg#Montserrat-Light')
			format('svg');
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Montserrat';
	src: url('/assets/fonts/montserrat/Montserrat-SemiBold.eot');
	src: url('/assets/fonts/montserrat/Montserrat-SemiBold.eot?#iefix')
			format('embedded-opentype'),
		url('/assets/fonts/montserrat/Montserrat-SemiBold.woff2') format('woff2'),
		url('/assets/fonts/montserrat/Montserrat-SemiBold.woff') format('woff'),
		url('/assets/fonts/montserrat/Montserrat-SemiBold.ttf') format('truetype'),
		url('/assets/fonts/montserrat/Montserrat-SemiBold.svg#Montserrat-SemiBold')
			format('svg');
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Montserrat';
	src: url('/assets/fonts/montserrat/Montserrat-MediumItalic.eot');
	src: url('/assets/fonts/montserrat/Montserrat-MediumItalic.eot?#iefix')
			format('embedded-opentype'),
		url('/assets/fonts/montserrat/Montserrat-MediumItalic.woff2')
			format('woff2'),
		url('/assets/fonts/montserrat/Montserrat-MediumItalic.woff') format('woff'),
		url('/assets/fonts/montserrat/Montserrat-MediumItalic.ttf')
			format('truetype'),
		url('/assets/fonts/montserrat/Montserrat-MediumItalic.svg#Montserrat-MediumItalic')
			format('svg');
	font-weight: 500;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Montserrat';
	src: url('/assets/fonts/montserrat/Montserrat-Regular.eot');
	src: url('/assets/fonts/montserrat/Montserrat-Regular.eot?#iefix')
			format('embedded-opentype'),
		url('/assets/fonts/montserrat/Montserrat-Regular.woff2') format('woff2'),
		url('/assets/fonts/montserrat/Montserrat-Regular.woff') format('woff'),
		url('/assets/fonts/montserrat/Montserrat-Regular.ttf') format('truetype'),
		url('/assets/fonts/montserrat/Montserrat-Regular.svg#Montserrat-Regular')
			format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Montserrat';
	src: url('/assets/fonts/montserrat/Montserrat-SemiBoldItalic.eot');
	src: url('/assets/fonts/montserrat/Montserrat-SemiBoldItalic.eot?#iefix')
			format('embedded-opentype'),
		url('/assets/fonts/montserrat/Montserrat-SemiBoldItalic.woff2')
			format('woff2'),
		url('/assets/fonts/montserrat/Montserrat-SemiBoldItalic.woff')
			format('woff'),
		url('/assets/fonts/montserrat/Montserrat-SemiBoldItalic.ttf')
			format('truetype'),
		url('/assets/fonts/montserrat/Montserrat-SemiBoldItalic.svg#Montserrat-SemiBoldItalic')
			format('svg');
	font-weight: 600;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Montserrat';
	src: url('/assets/fonts/montserrat/Montserrat-ThinItalic.eot');
	src: url('/assets/fonts/montserrat/Montserrat-ThinItalic.eot?#iefix')
			format('embedded-opentype'),
		url('/assets/fonts/montserrat/Montserrat-ThinItalic.woff2') format('woff2'),
		url('/assets/fonts/montserrat/Montserrat-ThinItalic.woff') format('woff'),
		url('/assets/fonts/montserrat/Montserrat-ThinItalic.ttf') format('truetype'),
		url('/assets/fonts/montserrat/Montserrat-ThinItalic.svg#Montserrat-ThinItalic')
			format('svg');
	font-weight: 100;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Montserrat';
	src: url('/assets/fonts/montserrat/Montserrat-Thin.eot');
	src: url('/assets/fonts/montserrat/Montserrat-Thin.eot?#iefix')
			format('embedded-opentype'),
		url('/assets/fonts/montserrat/Montserrat-Thin.woff2') format('woff2'),
		url('/assets/fonts/montserrat/Montserrat-Thin.woff') format('woff'),
		url('/assets/fonts/montserrat/Montserrat-Thin.ttf') format('truetype'),
		url('/assets/fonts/montserrat/Montserrat-Thin.svg#Montserrat-Thin')
			format('svg');
	font-weight: 100;
	font-style: normal;
	font-display: swap;
}
@font-face {
	font-family: 'Poppins';
	src: url('/assets/fonts/poppins/Poppins-BlackItalic.eot');
	src: url('/assets/fonts/poppins/Poppins-BlackItalic.eot?#iefix')
			format('embedded-opentype'),
		url('/assets/fonts/poppins/Poppins-BlackItalic.woff2') format('woff2'),
		url('/assets/fonts/poppins/Poppins-BlackItalic.woff') format('woff'),
		url('/assets/fonts/poppins/Poppins-BlackItalic.ttf') format('truetype'),
		url('/assets/fonts/poppins/Poppins-BlackItalic.svg#Poppins-BlackItalic')
			format('svg');
	font-weight: 900;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Poppins';
	src: url('/assets/fonts/poppins/Poppins-Bold.eot');
	src: url('/assets/fonts/poppins/Poppins-Bold.eot?#iefix')
			format('embedded-opentype'),
		url('/assets/fonts/poppins/Poppins-Bold.woff2') format('woff2'),
		url('/assets/fonts/poppins/Poppins-Bold.woff') format('woff'),
		url('/assets/fonts/poppins/Poppins-Bold.ttf') format('truetype'),
		url('/assets/fonts/poppins/Poppins-Bold.svg#Poppins-Bold') format('svg');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Poppins';
	src: url('/assets/fonts/poppins/Poppins-Black.eot');
	src: url('/assets/fonts/poppins/Poppins-Black.eot?#iefix')
			format('embedded-opentype'),
		url('/assets/fonts/poppins/Poppins-Black.woff2') format('woff2'),
		url('/assets/fonts/poppins/Poppins-Black.woff') format('woff'),
		url('/assets/fonts/poppins/Poppins-Black.ttf') format('truetype'),
		url('/assets/fonts/poppins/Poppins-Black.svg#Poppins-Black') format('svg');
	font-weight: 900;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Poppins';
	src: url('/assets/fonts/poppins/Poppins-ExtraBoldItalic.eot');
	src: url('/assets/fonts/poppins/Poppins-ExtraBoldItalic.eot?#iefix')
			format('embedded-opentype'),
		url('/assets/fonts/poppins/Poppins-ExtraBoldItalic.woff2') format('woff2'),
		url('/assets/fonts/poppins/Poppins-ExtraBoldItalic.woff') format('woff'),
		url('/assets/fonts/poppins/Poppins-ExtraBoldItalic.ttf') format('truetype'),
		url('/assets/fonts/poppins/Poppins-ExtraBoldItalic.svg#Poppins-ExtraBoldItalic')
			format('svg');
	font-weight: bold;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Poppins';
	src: url('/assets/fonts/poppins/Poppins-ExtraBold.eot');
	src: url('/assets/fonts/poppins/Poppins-ExtraBold.eot?#iefix')
			format('embedded-opentype'),
		url('/assets/fonts/poppins/Poppins-ExtraBold.woff2') format('woff2'),
		url('/assets/fonts/poppins/Poppins-ExtraBold.woff') format('woff'),
		url('/assets/fonts/poppins/Poppins-ExtraBold.ttf') format('truetype'),
		url('/assets/fonts/poppins/Poppins-ExtraBold.svg#Poppins-ExtraBold')
			format('svg');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Poppins';
	src: url('/assets/fonts/poppins/Poppins-ExtraLight.eot');
	src: url('/assets/fonts/poppins/Poppins-ExtraLight.eot?#iefix')
			format('embedded-opentype'),
		url('/assets/fonts/poppins/Poppins-ExtraLight.woff2') format('woff2'),
		url('/assets/fonts/poppins/Poppins-ExtraLight.woff') format('woff'),
		url('/assets/fonts/poppins/Poppins-ExtraLight.ttf') format('truetype'),
		url('/assets/fonts/poppins/Poppins-ExtraLight.svg#Poppins-ExtraLight')
			format('svg');
	font-weight: 200;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Poppins';
	src: url('/assets/fonts/poppins/Poppins-ExtraLightItalic.eot');
	src: url('/assets/fonts/poppins/Poppins-ExtraLightItalic.eot?#iefix')
			format('embedded-opentype'),
		url('/assets/fonts/poppins/Poppins-ExtraLightItalic.woff2') format('woff2'),
		url('/assets/fonts/poppins/Poppins-ExtraLightItalic.woff') format('woff'),
		url('/assets/fonts/poppins/Poppins-ExtraLightItalic.ttf') format('truetype'),
		url('/assets/fonts/poppins/Poppins-ExtraLightItalic.svg#Poppins-ExtraLightItalic')
			format('svg');
	font-weight: 200;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Poppins';
	src: url('/assets/fonts/poppins/Poppins-BoldItalic.eot');
	src: url('/assets/fonts/poppins/Poppins-BoldItalic.eot?#iefix')
			format('embedded-opentype'),
		url('/assets/fonts/poppins/Poppins-BoldItalic.woff2') format('woff2'),
		url('/assets/fonts/poppins/Poppins-BoldItalic.woff') format('woff'),
		url('/assets/fonts/poppins/Poppins-BoldItalic.ttf') format('truetype'),
		url('/assets/fonts/poppins/Poppins-BoldItalic.svg#Poppins-BoldItalic')
			format('svg');
	font-weight: bold;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Poppins';
	src: url('/assets/fonts/poppins/Poppins-Italic.eot');
	src: url('/assets/fonts/poppins/Poppins-Italic.eot?#iefix')
			format('embedded-opentype'),
		url('/assets/fonts/poppins/Poppins-Italic.woff2') format('woff2'),
		url('/assets/fonts/poppins/Poppins-Italic.woff') format('woff'),
		url('/assets/fonts/poppins/Poppins-Italic.ttf') format('truetype'),
		url('/assets/fonts/poppins/Poppins-Italic.svg#Poppins-Italic') format('svg');
	font-weight: normal;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Poppins';
	src: url('/assets/fonts/poppins/Poppins-Medium.eot');
	src: url('/assets/fonts/poppins/Poppins-Medium.eot?#iefix')
			format('embedded-opentype'),
		url('/assets/fonts/poppins/Poppins-Medium.woff2') format('woff2'),
		url('/assets/fonts/poppins/Poppins-Medium.woff') format('woff'),
		url('/assets/fonts/poppins/Poppins-Medium.ttf') format('truetype'),
		url('/assets/fonts/poppins/Poppins-Medium.svg#Poppins-Medium') format('svg');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Poppins';
	src: url('/assets/fonts/poppins/Poppins-LightItalic.eot');
	src: url('/assets/fonts/poppins/Poppins-LightItalic.eot?#iefix')
			format('embedded-opentype'),
		url('/assets/fonts/poppins/Poppins-LightItalic.woff2') format('woff2'),
		url('/assets/fonts/poppins/Poppins-LightItalic.woff') format('woff'),
		url('/assets/fonts/poppins/Poppins-LightItalic.ttf') format('truetype'),
		url('/assets/fonts/poppins/Poppins-LightItalic.svg#Poppins-LightItalic')
			format('svg');
	font-weight: 300;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Poppins';
	src: url('/assets/fonts/poppins/Poppins-Light.eot');
	src: url('/assets/fonts/poppins/Poppins-Light.eot?#iefix')
			format('embedded-opentype'),
		url('/assets/fonts/poppins/Poppins-Light.woff2') format('woff2'),
		url('/assets/fonts/poppins/Poppins-Light.woff') format('woff'),
		url('/assets/fonts/poppins/Poppins-Light.ttf') format('truetype'),
		url('/assets/fonts/poppins/Poppins-Light.svg#Poppins-Light') format('svg');
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Poppins';
	src: url('/assets/fonts/poppins/Poppins-MediumItalic.eot');
	src: url('/assets/fonts/poppins/Poppins-MediumItalic.eot?#iefix')
			format('embedded-opentype'),
		url('/assets/fonts/poppins/Poppins-MediumItalic.woff2') format('woff2'),
		url('/assets/fonts/poppins/Poppins-MediumItalic.woff') format('woff'),
		url('/assets/fonts/poppins/Poppins-MediumItalic.ttf') format('truetype'),
		url('/assets/fonts/poppins/Poppins-MediumItalic.svg#Poppins-MediumItalic')
			format('svg');
	font-weight: 500;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Poppins';
	src: url('/assets/fonts/poppins/Poppins-Thin.eot');
	src: url('/assets/fonts/poppins/Poppins-Thin.eot?#iefix')
			format('embedded-opentype'),
		url('/assets/fonts/poppins/Poppins-Thin.woff2') format('woff2'),
		url('/assets/fonts/poppins/Poppins-Thin.woff') format('woff'),
		url('/assets/fonts/poppins/Poppins-Thin.ttf') format('truetype'),
		url('/assets/fonts/poppins/Poppins-Thin.svg#Poppins-Thin') format('svg');
	font-weight: 100;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Poppins';
	src: url('/assets/fonts/poppins/Poppins-Regular.eot');
	src: url('/assets/fonts/poppins/Poppins-Regular.eot?#iefix')
			format('embedded-opentype'),
		url('/assets/fonts/poppins/Poppins-Regular.woff2') format('woff2'),
		url('/assets/fonts/poppins/Poppins-Regular.woff') format('woff'),
		url('/assets/fonts/poppins/Poppins-Regular.ttf') format('truetype'),
		url('/assets/fonts/poppins/Poppins-Regular.svg#Poppins-Regular')
			format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Poppins';
	src: url('/assets/fonts/poppins/Poppins-SemiBold.eot');
	src: url('/assets/fonts/poppins/Poppins-SemiBold.eot?#iefix')
			format('embedded-opentype'),
		url('/assets/fonts/poppins/Poppins-SemiBold.woff2') format('woff2'),
		url('/assets/fonts/poppins/Poppins-SemiBold.woff') format('woff'),
		url('/assets/fonts/poppins/Poppins-SemiBold.ttf') format('truetype'),
		url('/assets/fonts/poppins/Poppins-SemiBold.svg#Poppins-SemiBold')
			format('svg');
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Poppins';
	src: url('/assets/fonts/poppins/Poppins-SemiBoldItalic.eot');
	src: url('/assets/fonts/poppins/Poppins-SemiBoldItalic.eot?#iefix')
			format('embedded-opentype'),
		url('/assets/fonts/poppins/Poppins-SemiBoldItalic.woff2') format('woff2'),
		url('/assets/fonts/poppins/Poppins-SemiBoldItalic.woff') format('woff'),
		url('/assets/fonts/poppins/Poppins-SemiBoldItalic.ttf') format('truetype'),
		url('/assets/fonts/poppins/Poppins-SemiBoldItalic.svg#Poppins-SemiBoldItalic')
			format('svg');
	font-weight: 600;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Poppins';
	src: url('/assets/fonts/poppins/Poppins-ThinItalic.eot');
	src: url('/assets/fonts/poppins/Poppins-ThinItalic.eot?#iefix')
			format('embedded-opentype'),
		url('/assets/fonts/poppins/Poppins-ThinItalic.woff2') format('woff2'),
		url('/assets/fonts/poppins/Poppins-ThinItalic.woff') format('woff'),
		url('/assets/fonts/poppins/Poppins-ThinItalic.ttf') format('truetype'),
		url('/assets/fonts/poppins/Poppins-ThinItalic.svg#Poppins-ThinItalic')
			format('svg');
	font-weight: 100;
	font-style: italic;
	font-display: swap;
}
@font-face {
	font-family: 'Roboto';
	src: url('/assets/fonts/roboto/Roboto-Bold.eot');
	src: url('/assets/fonts/roboto/Roboto-Bold.eot?#iefix')
			format('embedded-opentype'),
		url('/assets/fonts/roboto/Roboto-Bold.woff2') format('woff2'),
		url('/assets/fonts/roboto/Roboto-Bold.woff') format('woff'),
		url('/assets/fonts/roboto/Roboto-Bold.ttf') format('truetype'),
		url('/assets/fonts/roboto/Roboto-Bold.svg#Roboto-Bold') format('svg');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Roboto';
	src: url('/assets/fonts/roboto/Roboto-BlackItalic.eot');
	src: url('/assets/fonts/roboto/Roboto-BlackItalic.eot?#iefix')
			format('embedded-opentype'),
		url('/assets/fonts/roboto/Roboto-BlackItalic.woff2') format('woff2'),
		url('/assets/fonts/roboto/Roboto-BlackItalic.woff') format('woff'),
		url('/assets/fonts/roboto/Roboto-BlackItalic.ttf') format('truetype'),
		url('/assets/fonts/roboto/Roboto-BlackItalic.svg#Roboto-BlackItalic')
			format('svg');
	font-weight: 900;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Roboto';
	src: url('/assets/fonts/roboto/Roboto-Black.eot');
	src: url('/assets/fonts/roboto/Roboto-Black.eot?#iefix')
			format('embedded-opentype'),
		url('/assets/fonts/roboto/Roboto-Black.woff2') format('woff2'),
		url('/assets/fonts/roboto/Roboto-Black.woff') format('woff'),
		url('/assets/fonts/roboto/Roboto-Black.ttf') format('truetype'),
		url('/assets/fonts/roboto/Roboto-Black.svg#Roboto-Black') format('svg');
	font-weight: 900;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Roboto';
	src: url('/assets/fonts/roboto/Roboto-Light.eot');
	src: url('/assets/fonts/roboto/Roboto-Light.eot?#iefix')
			format('embedded-opentype'),
		url('/assets/fonts/roboto/Roboto-Light.woff2') format('woff2'),
		url('/assets/fonts/roboto/Roboto-Light.woff') format('woff'),
		url('/assets/fonts/roboto/Roboto-Light.ttf') format('truetype'),
		url('/assets/fonts/roboto/Roboto-Light.svg#Roboto-Light') format('svg');
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Roboto';
	src: url('/assets/fonts/roboto/Roboto-BoldItalic.eot');
	src: url('/assets/fonts/roboto/Roboto-BoldItalic.eot?#iefix')
			format('embedded-opentype'),
		url('/assets/fonts/roboto/Roboto-BoldItalic.woff2') format('woff2'),
		url('/assets/fonts/roboto/Roboto-BoldItalic.woff') format('woff'),
		url('/assets/fonts/roboto/Roboto-BoldItalic.ttf') format('truetype'),
		url('/assets/fonts/roboto/Roboto-BoldItalic.svg#Roboto-BoldItalic')
			format('svg');
	font-weight: bold;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Roboto';
	src: url('/assets/fonts/roboto/Roboto-Italic.eot');
	src: url('/assets/fonts/roboto/Roboto-Italic.eot?#iefix')
			format('embedded-opentype'),
		url('/assets/fonts/roboto/Roboto-Italic.woff2') format('woff2'),
		url('/assets/fonts/roboto/Roboto-Italic.woff') format('woff'),
		url('/assets/fonts/roboto/Roboto-Italic.ttf') format('truetype'),
		url('/assets/fonts/roboto/Roboto-Italic.svg#Roboto-Italic') format('svg');
	font-weight: normal;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Roboto';
	src: url('/assets/fonts/roboto/Roboto-LightItalic.eot');
	src: url('/assets/fonts/roboto/Roboto-LightItalic.eot?#iefix')
			format('embedded-opentype'),
		url('/assets/fonts/roboto/Roboto-LightItalic.woff2') format('woff2'),
		url('/assets/fonts/roboto/Roboto-LightItalic.woff') format('woff'),
		url('/assets/fonts/roboto/Roboto-LightItalic.ttf') format('truetype'),
		url('/assets/fonts/roboto/Roboto-LightItalic.svg#Roboto-LightItalic')
			format('svg');
	font-weight: 300;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Roboto';
	src: url('/assets/fonts/roboto/Roboto-Medium.eot');
	src: url('/assets/fonts/roboto/Roboto-Medium.eot?#iefix')
			format('embedded-opentype'),
		url('/assets/fonts/roboto/Roboto-Medium.woff2') format('woff2'),
		url('/assets/fonts/roboto/Roboto-Medium.woff') format('woff'),
		url('/assets/fonts/roboto/Roboto-Medium.ttf') format('truetype'),
		url('/assets/fonts/roboto/Roboto-Medium.svg#Roboto-Medium') format('svg');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Roboto';
	src: url('/assets/fonts/roboto/Roboto-Thin.eot');
	src: url('/assets/fonts/roboto/Roboto-Thin.eot?#iefix')
			format('embedded-opentype'),
		url('/assets/fonts/roboto/Roboto-Thin.woff2') format('woff2'),
		url('/assets/fonts/roboto/Roboto-Thin.woff') format('woff'),
		url('/assets/fonts/roboto/Roboto-Thin.ttf') format('truetype'),
		url('/assets/fonts/roboto/Roboto-Thin.svg#Roboto-Thin') format('svg');
	font-weight: 100;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Roboto';
	src: url('/assets/fonts/roboto/Roboto-Regular.eot');
	src: url('/assets/fonts/roboto/Roboto-Regular.eot?#iefix')
			format('embedded-opentype'),
		url('/assets/fonts/roboto/Roboto-Regular.woff2') format('woff2'),
		url('/assets/fonts/roboto/Roboto-Regular.woff') format('woff'),
		url('/assets/fonts/roboto/Roboto-Regular.ttf') format('truetype'),
		url('/assets/fonts/roboto/Roboto-Regular.svg#Roboto-Regular') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Roboto';
	src: url('/assets/fonts/roboto/Roboto-ThinItalic.eot');
	src: url('/assets/fonts/roboto/Roboto-ThinItalic.eot?#iefix')
			format('embedded-opentype'),
		url('/assets/fonts/roboto/Roboto-ThinItalic.woff2') format('woff2'),
		url('/assets/fonts/roboto/Roboto-ThinItalic.woff') format('woff'),
		url('/assets/fonts/roboto/Roboto-ThinItalic.ttf') format('truetype'),
		url('/assets/fonts/roboto/Roboto-ThinItalic.svg#Roboto-ThinItalic')
			format('svg');
	font-weight: 100;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Roboto';
	src: url('/assets/fonts/roboto/Roboto-MediumItalic.eot');
	src: url('/assets/fonts/roboto/Roboto-MediumItalic.eot?#iefix')
			format('embedded-opentype'),
		url('/assets/fonts/roboto/Roboto-MediumItalic.woff2') format('woff2'),
		url('/assets/fonts/roboto/Roboto-MediumItalic.woff') format('woff'),
		url('/assets/fonts/roboto/Roboto-MediumItalic.ttf') format('truetype'),
		url('/assets/fonts/roboto/Roboto-MediumItalic.svg#Roboto-MediumItalic')
			format('svg');
	font-weight: 500;
	font-style: italic;
	font-display: swap;
}

html {
	height: 100%;
	background: $background-color;
}

:root {
	--theme-color-1: #{$theme-color-1};
	--theme-color-2: #{$theme-color-2};
	--theme-color-3: #{$theme-color-3};
	--theme-color-4: #{$theme-color-4};
	--theme-color-5: #{$theme-color-5};
	--theme-color-6: #{$theme-color-6};
	--theme-color-1-10: #{rgba($theme-color-1, 0.1)};
	--theme-color-2-10: #{rgba($theme-color-2, 0.1)};
	--theme-color-3-10: #{rgba($theme-color-3, 0.1)};
	--theme-color-4-10: #{rgba($theme-color-4, 0.1)};
	--theme-color-5-10: #{rgba($theme-color-5, 0.1)};
	--theme-color-6-10: #{rgba($theme-color-6, 0.1)};
	--primary-color: #{$primary-color};
	--foreground-color: #{$foreground-color};
	--separator-color: #{$separator-color};
}

#root {
	height: 100%;
	width: 100%;
}

body {
	font-family: Roboto, 'Helvetica Neue', Arial, 'PingFang SC', 'Microsoft YaHei',
		'Hei SC', SimHei, '黑体', sans-serif;
	font-size: 1.2rem;
	font-weight: 300;
	color: $text-color;
	background: $background-color;
	height: 100%;
	line-height: 1.75;

	&.background {
		height: 100%;

		main {
			margin: 0 !important;
			height: 100%;

			.container {
				height: 100%;
			}
		}
	}
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: 'Suez One', 'PingFang SC', 'Microsoft YaHei', 'Hei SC', SimHei,
		'黑体', sans-serif;
}

h1 {
	font-size: 4.5rem;
	@include respond-below(sm) {
		font-size: 2.4rem;
	}
}

h2 {
	font-size: 3.5rem;
	@include respond-below(sm) {
		font-size: 2.25rem;
	}
}

h3 {
	font-size: 1.8rem;
	@include respond-below(sm) {
		font-size: 1.5rem;
	}
}

h4 {
	font-size: 1.15rem;
	@include respond-below(sm) {
		font-size: 0.9rem;
	}
}

h5 {
	font-size: 1.1rem;
	@include respond-below(sm) {
		font-size: 0.9rem;
	}
}

h6 {
	font-size: 1rem;
	@include respond-below(sm) {
		font-size: 0.85rem;
	}
}

hr {
	border-top: 1px solid $separator-color;
}

.divider {
	height: 150px;
	width: 100%;
	position: absolute;
	bottom: 0;

	@include respond-below(sm) {
		height: 80px;
	}
}

.divider-layer {
	background-image: url('/assets/img/divider-bottom.svg');
	width: 100%;
	position: absolute;
	bottom: 0;
}
.divider-layer-0 {
	height: 90px;
	opacity: 1;
	@include respond-below(sm) {
		height: 50px;
	}
}
.divider-layer-1 {
	height: 110px;
	opacity: 0.5;
	@include respond-below(sm) {
		height: 60px;
	}
}
.divider-layer-2 {
	height: 130px;
	opacity: 0.333333;
	@include respond-below(sm) {
		height: 70px;
	}
}
.divider-layer-3 {
	height: 150px;
	opacity: 0.25;
	@include respond-below(sm) {
		height: 80px;
	}
}

.disable-text-selection {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

input {
	-webkit-appearance: none;
}

::selection {
	background: lighten($theme-color-3, 20%);
}

::-moz-selection {
	background: lighten($theme-color-3, 20%);
}

.map-item {
	height: 400px;
	width: 100%;
}

.scrollbar-container {
	margin-right: -15px;
	padding-right: 15px;
	position: relative;
}

.ps__rail-y {
	width: 5px;
}

.ps__thumb-y {
	left: 0;
	width: 5px;
	right: 0;
}

.ps__rail-y:hover > .ps__thumb-y,
.ps__rail-y:focus > .ps__thumb-y,
.ps__rail-y.ps--clicking .ps__thumb-y {
	width: 5px;
	left: 0;
}

.ps__thumb-y {
	background-color: $separator-color;
}

.video-play-icon {
	width: 100%;
	height: 100%;
	position: absolute;

	span {
		position: absolute;
		background: rgba(255, 255, 255, 0.7);
		height: 1.25em;
		border-radius: 0.75em;
		line-height: 0.65em;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		font-size: 1.75em;
		width: 2em;
		text-align: center;

		&:before {
			content: '';
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 8px 0 8px 12px;
			font-size: 0;
			border-color: transparent transparent transparent $theme-color-1;
		}
	}

	&:hover,
	&:active {
		span {
			background: rgba(255, 255, 255, 0.85);
		}
	}
}

.logo-single {
	width: 60px;
	height: 60px;
	// background: url($logo-path) no-repeat;
	background-position: center center;
	display: inline-block;
	margin-bottom: 30px;
}

.list-item-heading-container {
	width: calc(100% - 125px);
}

.list-item-heading {
	font-size: 1rem;
}

.truncate {
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

.no-transition {
	transition: none !important;
}

.display-1 {
	@include respond-below(sm) {
		font-size: 2.8rem;
	}
}

.display-2 {
	@include respond-below(sm) {
		font-size: 2.6rem;
	}
}

.display-3 {
	@include respond-below(sm) {
		font-size: 2.4rem;
	}
}

.display-4 {
	@include respond-below(sm) {
		font-size: 2.2rem;
	}
}

.lead {
	font-size: 1.8rem;
	font-weight: 300;
	line-height: 2rem;

	@include respond-below(sm) {
		font-size: 1.1rem;
		line-height: 1.6rem;
	}
}

a {
	color: $primary-color;
	transition: color $animation-time-short;

	&:hover,
	&:active {
		text-decoration: initial;
		color: $theme-color-1;
	}
}

p {
	font-family: montserrat, 'PingFang SC', 'Microsoft YaHei', 'Hei SC', SimHei,
		'黑体', sans-serif;
	font-size: 0.85rem;
	line-height: 1.3rem;
}

.text-large {
	font-size: 1.9rem !important;
}

.text-one {
	font-size: 1rem !important;
}

.text-xlarge {
	font-size: 2.7rem !important;
}

.text-small {
	font-size: 0.76rem;
	line-height: 0.9rem;
}

.text-white {
	color: $button-text-color !important;
}

.text-extra-small {
	font-size: 0.6rem;
}

.text-default {
	color: $primary-color !important;
}

.text-muted {
	color: $muted-color !important;
}
.text-gray {
	color: $text-dark-gray-color !important;
}

.text-semi-muted {
	color: $secondary-color !important;
}

.text-gradient {
	background: linear-gradient(105deg, $theme-color-2, $theme-color-1);
	-webkit-background-clip: text;
	background-clip: text;
	color: transparent;
}

.font-weight-medium {
	font-weight: 500;
}

.font-weight-semibold {
	font-weight: 600;
}

.color-theme-1 {
	color: $theme-color-1;
}

.color-theme-2 {
	color: $theme-color-2;
}

.view-icon {
	font-size: 20px;
	color: $secondary-color;

	&:hover {
		color: $theme-color-1;
	}

	&.s {
		font-size: 18px;
	}
}

.text-theme-2 {
	color: $theme-color-2 !important;
}

.text-theme-3 {
	color: $theme-color-3 !important;
}

.text-primary,
.text-theme-1 {
	color: $theme-color-1 !important;
}

.text-secondary {
	color: $primary-color !important;
}

.main-heading {
	border-bottom: 1px solid $separator-color;
}

.separator {
	border-bottom: 1px solid $separator-color;
}

.alert-dismissible .close {
	padding: 0.5rem 1.25rem;
}

.rounded {
	border-radius: 50px !important;
}

.img-thumbnail {
	border-radius: $border-radius;
	padding: 0;
	border: initial;
}
.img-fluid {
	width: 100%;
}
.white {
	color: $button-text-color !important;
}

.opacity-25 {
	opacity: 0.25;
}

.opacity-50 {
	opacity: 0.5;
}

.opacity-75 {
	opacity: 0.75;
}
.jumbotron {
	background: initial;
}
.c-pointer {
	cursor: pointer !important;
}

.rtl * {
	text-align: right;
}

.rtl {
	direction: rtl;
	.text-center p,
	.text-center div,
	.text-center span,
	.text-center i,
	.text-center a,
	.text-center h1,
	.text-center h2,
	.text-center h3,
	.text-center h4,
	.text-center h5,
	.text-center h6 {
		text-align: center;
	}

	.list-unstyled {
		padding-right: 0;
	}

	.dropdown-menu:not(.datepicker-dropdown) {
		right: initial !important;
	}

	.dropdown-menu-right {
		right: initial !important;
		left: 0 !important;
	}

	.dropdown-menu-left {
		right: 0 !important;
		left: initial !important;
	}
	.float-right {
		float: left !important;
	}

	.float-left {
		float: right !important;
	}

	@include respond-above(xl) {
		.float-xxl-left {
			float: right !important;
		}
		.float-xxl-right {
			float: left !important;
		}
	}

	@include respond-above(lg) {
		.float-xl-left {
			float: right !important;
		}
		.float-xl-right {
			float: left !important;
		}
	}

	@include respond-above(md) {
		.float-lg-left {
			float: right !important;
		}
		.float-lg-right {
			float: left !important;
		}
	}

	@include respond-above(sm) {
		.float-md-left {
			float: right !important;
		}
		.float-md-right {
			float: left !important;
		}
	}

	@include respond-above(xs) {
		.float-sm-left {
			float: right !important;
		}
		.float-sm-right {
			float: left !important;
		}
	}

	@include respond-above(xxs) {
		.float-xs-left {
			float: right !important;
		}
		.float-xs-right {
			float: left !important;
		}
	}
	.r-0 {
		left: 0;
		right: initial;
	}
	.heading-number {
		margin-right: 0;
		margin-left: 10px;
	}

	.ps__rail-y {
		right: initial !important;
		left: 0 !important;
	}

	.scroll {
		margin-left: -15px;
		padding-left: 15px;
		margin-right: 0;
		padding-right: 0;
	}
	.icon-button {
		text-align: center;
	}

	.heading-icon {
		margin-right: initial;
		margin-left: 5px;
	}

	.simple-icon-arrow-left:before {
		content: '\e606';
	}

	.simple-icon-arrow-right:before {
		content: '\e605';
	}
}

.rounded {
	.app-menu {
		border-top-left-radius: $border-radius-rounded;
		border-bottom-left-radius: $border-radius-rounded;
	}

	.dropdown-menu {
		border-radius: $border-radius-rounded;
	}

	.btn.default {
		border-radius: $border-radius;
	}

	.list-thumbnail {
		border-radius: $border-radius-rounded;
	}
}
