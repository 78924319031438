.home {
	width: 100%;
	height: 100%;
	overflow: hidden;
	.section-1 {
		position: relative;
		background-color: #000;
		overflow: hidden;
		height: 720px;
		@include respond-below(sm) {
			height: 560px;
		}
		video {
			width: 100%;
			height: 100%;
			object-fit: cover;
			opacity: 0.75;
		}
		.slogan-container {
			position: absolute;
			width: 100%;
			top: 0;
			bottom: 0;
			z-index: 1048;
			display: flex;
			align-items: center;
			justify-content: center;
		}
		.slogan {
			color: white;
			text-align: center;
			line-height: 1.25;
			@include respond-below(sm) {
				font-size: 2.2rem;
				text-align: center;
			}
		}
	}
	.section-2 {
		position: relative;
		z-index: 1047;
		padding: 10rem 5rem;
		@include respond-below(md) {
			padding: 8rem 0;
		}
		@include respond-below(sm) {
			padding: 5rem 0;
		}

		.about {
			position: relative;
			height: 720px;
			@include respond-below(md) {
				height: auto;
			}
			@include respond-below(sm) {
				height: auto;
			}

			.video-container {
				position: absolute;
				left: 0;
				width: 60%;
				height: 640px;
				@include respond-below(md) {
					width: 100%;
					height: 240px;
					position: relative;
				}
				@include respond-below(sm) {
					width: 100%;
					height: 240px;
					position: relative;
				}
				video {
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
			}
			.content {
				position: absolute;
				right: 0;
				bottom: 0px;
				width: 60%;
				padding: 3rem 10rem;
				background-color: white;
				.slogan {
					position: relative;
					left: -10rem;
					transition: 1s linear;
					transition-delay: 200ms;
					&.animated {
						left: 0 !important;
					}
				}
				@include respond-below(md) {
					width: 100%;
					position: relative;
					padding: 3rem;
					.slogan {
						left: -3rem;
					}
				}
				@include respond-below(sm) {
					width: 100%;
					position: relative;
					padding: 3rem;
					.slogan {
						left: -3rem;
					}
				}
			}
		}

		.btn {
			font-size: 1rem;
			margin-top: 3rem;
		}
	}
	.section-3 {
		position: relative;
		padding-top: 10rem;
		padding-bottom: 10rem;
		.animation {
			position: relative;
			opacity: 0;
			top: 5rem;
			transition: 1s all;
			transition-delay: 200ms;
			&.animated {
				opacity: 1;
				top: 0;
			}
		}
		.home-portfolio {
			position: relative;
			z-index: 1031;
			margin-bottom: 3rem;
			.slogan {
				text-align: center;
				margin: 0 auto 2rem;
			}
			.desc {
				color: $text-dark-gray-color;
				text-align: center;
			}
			.btn {
				font-size: 1rem;
			}
		}
		.companies {
			position: relative;
			z-index: 1031;
			.company {
				position: relative;
				display: inline-block;
				margin: 0 0.5rem;
				.bg {
					width: 100%;
					opacity: 1;
				}
				.content {
					position: absolute;
					top: 0;
					bottom: 0;
					width: 100%;
					padding: 0;

					.logo-container {
						position: absolute;
						left: 0;
						top: 0;
						bottom: 0;
						display: flex;
						align-items: center;
						.logo {
							width: 100%;
						}
						transition: 0.3s linear;
					}
					.brief {
						width: 100%;
						position: relative;
						top: 30%;
						opacity: 0;
						text-align: center;
						color: white;
						padding: 15px;
						white-space: normal;
						font-size: 1rem;
						line-height: 1.5;
						transition: 0.5s linear;
					}
					.learn-more {
						opacity: 0;
						position: absolute;
						width: 100%;
						bottom: 0;
						text-align: center;
						color: white;
						font-size: 0.75rem;
						padding-bottom: 1rem;
						transition: 0.5s linear;
					}
				}
				&:hover {
					display: block;
					align-items: unset;
					.bg {
						opacity: 0;
					}
					.logo-container {
						bottom: 60%;
					}
					.brief {
						opacity: 1;
					}
					.learn-more {
						opacity: 1;
					}
				}
			}
		}
	}
	.section-4 {
		background: url('/assets/img/home-team-bg.jpg') center center no-repeat;
		background-size: cover;
		padding-top: 13rem;
		padding-bottom: 13rem;
		.home-team {
			position: relative;
			z-index: 1031;
			.slogan {
				text-align: center;
				margin-bottom: 2rem;
				color: white;
			}
			.desc {
				text-align: center;
			}
			.btn {
				font-size: 1rem;
				margin-top: 2rem;
			}
		}
	}
	.section-6 {
		position: relative;
		padding-top: 5rem;
		padding-bottom: 5rem;
		// background: url('/assets/img/home-press-bg.jpg') center center no-repeat;
		// background-size: cover;
		video {
			position: absolute;
			top: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
		.home-press {
			position: relative;
			z-index: 1031;
			margin-bottom: 3rem;
			overflow: hidden;
			> .title {
				line-height: 1.25;
				text-align: center;
				margin-bottom: 4rem;
			}
			.item {
				padding: 2rem 0;
				display: flex;
				justify-content: space-between;
				align-items: center;
				.content {
					.title {
						font-weight: normal;
						text-align: left;
						margin-bottom: 1rem;
					}
					.meta {
						margin-bottom: 0;
						display: flex;
						align-items: center;
						font-size: 0.7rem;
					}
				}
				.link {
					margin: 0;
					font-size: 1rem;
				}
			}

			&.animated {
				.divider-line {
					left: 0;
				}
				.divider-line-layer {
					left: 0;
				}
			}

			.divider-line {
				width: 100%;
				border-bottom: black 1px solid;
				position: relative;
				left: 30%;
				transition: 1s all;
				transition-delay: 200ms;
			}

			.divider-line-layer {
				border-bottom: black 3px solid;
				margin-bottom: -2px;
				position: relative;
				left: 60%;
				transition: 2s all;
				transition-delay: 200ms;
			}

			.divider-line-layer-0 {
				width: 40%;
			}
			.divider-line-layer-1 {
				width: 80%;
			}
			.divider-line-layer-2 {
				width: 65%;
			}
			.divider-line-layer-3 {
				width: 90%;
			}
			.divider-line-layer-4 {
				width: 30%;
			}
			.btn {
				font-size: 1rem;
				margin-top: 3rem;
			}
		}
	}
}
