.cross-link {
	background: url('/assets/img/cross-link-bg.jpg') #111a29 center center no-repeat;
	background-size: cover;
	padding-bottom: 50px;
	margin-top: 100px;
	.card {
		padding: 2rem 4rem;
		margin-top: -50px;
		font-size: 1.25rem;
		@include respond-below(md) {
			padding: 2rem;
		}
		@include respond-below(sm) {
			font-size: 1rem;
		}
		h1 {
			font-size: 3rem;
			margin-bottom: 0;
			@include respond-below(sm) {
				font-size: 2.25rem;
			}
		}
	}
}
