@keyframes color-animation-1 {
	0%,
	100% {
		filter: hue-rotate(0deg);
	}

	50% {
		filter: hue-rotate(90deg);
	}
}

@keyframes color-animation-2 {
	0%,
	100% {
		filter: hue-rotate(0deg);
	}

	50% {
		filter: hue-rotate(-90deg);
	}
}

@keyframes color-animation-3 {
	0%,
	100% {
		filter: hue-rotate(45deg);
	}

	50% {
		filter: hue-rotate(-45deg);
	}
}

@keyframes color-animation-4 {
	0%,
	100% {
		filter: hue-rotate(-20deg);
	}

	50% {
		filter: hue-rotate(70deg);
	}
}
