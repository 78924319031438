.press {
	padding-bottom: 5rem;
	.banner {
		height: 400px;
		width: 100%;
		margin-bottom: 3rem;
		background: url('/assets/img/press-banner.jpg') center no-repeat;
		background-size: cover;
	}
	.item {
		background-color: rgba($color: white, $alpha: 0.6);
		margin-bottom: 2rem;
		padding: 2.5rem;
		transition: 200ms all;
		.content {
			height: 180px;
			margin-bottom: 40px;
		}
		.link {
			// color: $theme-color-1;
			padding: 0;
			margin: 0;
			font-size: 1rem;
			transition: 200ms all;
			&:hover {
				text-decoration: underline;
				letter-spacing: 1px;
				color: $theme-color-1;
			}
		}
		&:hover {
			margin-top: -10px;
		}
	}
}
