.team {
	width: 100%;
	overflow-x: hidden;
	.section-1 {
		margin-bottom: 3rem;
		padding-bottom: 3rem;
		text-align: center;
		.banner {
			width: 100%;
			height: 800px;
			position: relative;
			@include respond-below(md) {
				height: 600px;
			}
			@include respond-below(sm) {
				height: 500px;
			}
			video {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
			.container {
				height: 800px;
				@include respond-below(md) {
					height: 600px;
				}
				@include respond-below(sm) {
					height: 500px;
				}
			}
			.slogan {
				line-height: 1.25;
				color: white;
				position: relative;
			}
		}
		.desc {
			p {
				font-size: 1.5rem;
				line-height: 1.8;
				@include respond-below(sm) {
					font-size: 1.25rem;
					line-height: 1.5;
				}
			}
		}
	}
	.members {
		margin-bottom: 5rem;
		.member {
			background: transparent;
			box-shadow: none;
			margin-bottom: 3rem;
			transition: 0.2s linear;
			display: flex;
			justify-content: center;
			&:hover {
				.avatar {
					opacity: 0.8;
				}
			}
			.avatar {
				position: relative;
				width: 230px;
				height: 280px;
				overflow: hidden;
				border-radius: 8px;
				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
			}
			.name {
				position: relative;
				width: 210px;
				right: -30px;
				bottom: 20px;
				padding: 0.5rem 1rem;
				background-color: #efefef;
			}
		}
	}
}

.team-modal {
	.modal-content {
		background-color: $background-color;
	}
}

.team-bio {
	width: 60%;
	min-width: 1200px;
	margin: 0 auto;

	@include respond-below(lg) {
		width: 80%;
		min-width: 900px;
	}
	@include respond-below(md) {
		width: 100%;
		min-width: unset;
	}
	.highlight {
		.avatar-container {
			display: inline-block;
			vertical-align: top;
			height: 450px;
			width: 320px;
			.avatar {
				height: 100%;
				width: 100%;
				object-fit: cover;
			}
		}
		.details {
			display: inline-block;
			vertical-align: top;
			width: calc(100% - 321px);
		}
		.name {
			display: inline-block;
		}
		.position {
			display: inline-block;
			font-size: 1.4rem;
			margin-bottom: 30px;
		}
		p {
			display: inline-block;
			font-size: 1.4rem;
			line-height: 1.8;
			margin-top: 3rem;
		}
		@include respond-below(sm) {
			.avatar-container {
				display: inline-block;
				vertical-align: top;
				height: auto;
				width: 100%;
			}
			.details {
				display: inline-block;
				vertical-align: top;
				width: 100%;
			}
		}
	}
	.cv {
		p {
			font-size: 1.25rem;
			line-height: 2;
			margin-top: 3rem;
		}
	}
}
