footer.page-footer {
	position: relative;
	z-index: 1046;
	padding-top: 10rem;
	width: 100%;
	background-color: #f8fcfe;
	overflow: hidden;

	video {
		position: absolute;
		top: 0;
		width: 100%;
		height: 100%;
		z-index: 1;
		object-fit: cover;
	}

	.container {
		position: relative;
		z-index: 1047;
		color: white;
	}

	.list-group {
		.list-group-item {
			background-color: transparent !important;
			padding-left: 0;
			padding-right: 0;
			border: 0 !important;
			color: white;
			.nav-link {
				padding: 0;
				&:hover {
					color: $theme-color-1 !important;
				}
			}
			&.active {
				.nav-link {
					color: $theme-color-1 !important;
				}
			}
		}
	}

	.footer-bottom {
		position: relative;
		z-index: 1047;
		font-size: 1rem;
		padding: 1.25rem 0;
		margin-top: 5rem;
		// background: linear-gradient(105deg, $theme-color-2, $theme-color-1);
		background-color: #1e3b3a;
		color: white;
		.copyright {
			padding: 0.5rem 0;
		}
	}
}
